/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { storeToken, storeRole, storeRefreshToken, storeName, storePermisos } from '../utils/indexedDB';
import { handleSessionCleanup } from '../helpers/Helpers'
import privateAxios from '../utils/privateAxios';

//======================================== CONTEXTO ========================================//
const AuthContext = createContext();
//======================================== CONTEXTO ========================================//
// Función para manejar errores de autenticación

//======================================== PROVIDER ========================================//
const AuthProvider = ({ children }) => {
    const navigate = useNavigate();

    /**
     * Autentica el usuario con username y password.
     * @param {string} username 
     * @param {string} password 
     * @returns {Object} Resultado de la autenticación.
     */
    const login = async (username, password) => {
        try {
            const response = await privateAxios.post(`/login`, { username, password });

            if (response.status === 200 && response.data.success) {                
                const { token, refreshToken, role, fullName, permisos } = response.data;
                await storeToken(token);
                await storeRefreshToken(refreshToken);
                await storeRole(role);
                await storeName(fullName);
                await storePermisos(permisos)
                return { success: true, role: role };
            } else {
                return { success: false, message: response.data.message  };
            }
        } catch (error) {
            return null;
        }
    };

    /**
    * Valida el token almacenado.
    * @returns {boolean} Indica si el token es válido.
    */
    const validateToken = async () => {
        try {
            const response = await privateAxios.post(`/auth`);

            if (response.data.success && response.data.status)
                return response.data.status;

            await handleSessionCleanup();
            navigate('/login');
            return false;
        } catch (error) {
            navigate('/login'); 
            return false;
        }
    };

    /**
    * Verifica si el usuario autenticado es administrador.
    * @returns {boolean} Indica si el usuario es administrador.
    */
    const checkIfAdmin = async () => {
        try {
            const response = await privateAxios.get(`/auth/is-admin`);

            if (response.data.success && response.data.status) {
                return response.data.status; 
            }

            await handleSessionCleanup();
            navigate('/login'); 
            return false;
        } catch (error) {
            navigate('/login'); 
            return false;
        }
    };

    /**
    * Cierra la sesión
    */
    const logout = async () => {
        await handleSessionCleanup();
        navigate('/login');
    };

    //========================================
    // Métodos de gestión de contraseñas
    //========================================

    /**
     * Solicita el restablecimiento de la contraseña.
     * @param {string} email 
     * @returns {boolean} Indica si la solicitud fue exitosa.
     */
    const requestPasswordReset = async (email) => {
        try {
            const response = await privateAxios.post(`/reset-password/forget`, { email });
            return { success: response.data.success, message: response.data.message };
        } catch (error) {          
            if (error.response && error.response.data) 
                return { success: false, message: error.response.data.message || 'Ocurrió un error al procesar la solicitud.' };
            else 
                return { success: false, message: 'No se pudo conectar con el servidor.' };
        }
    };

    /**
     * Verifica si el token de restablecimiento de contraseña es válido.
     * @param {string} token 
     * @returns {Object} Indica si el token es válido y el mensaje.
     */
    const verifyResetToken = async (token) => {
        try {
            const response = await privateAxios.get(`/reset-password/reset`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            return { success: response.data.success, message: response.data.message };
        } catch (error) {         
            if (error.response && error.response.data)
                return { success: false, message: error.response.data.message || 'Token inválido o expirado.' };
            else 
                return { success: false, message: 'No se pudo conectar con el servidor.' };
        }
    };

    /**
     * Restablece la contraseña usando el token proporcionado.
     * @param {string} token 
     * @param {string} newPassword 
     * @returns {boolean} Indica si el restablecimiento fue exitoso.
     */
    const resetPassword = async (token, newPassword) => {
        try {
        const response = await privateAxios.post(`/reset-password/reset`, { newPassword }, {
            headers: {
            'Authorization': `Bearer ${token}`
            }
        });
        return { success: response.data.success, message: response.data.message };
        } catch (error) {
            if (error.response && error.response.data)
                return { success: false, message: error.response.data.message || 'Token inválido o expirado.' };
            else 
                return { success: false, message: 'No se pudo conectar con el servidor.' };
        }
    };

    /**
     * Cambia la contraseña del usuario actual.
     * @param {string} currentPassword 
     * @param {string} newPassword 
     * @returns {boolean} Indica si el cambio de contraseña fue exitoso.
     */
    const changePassword = async (currentPassword, newPassword) => {
        try {
            const response = await privateAxios.post(`/change-password/`, { currentPassword, newPassword });
            return response.data;
        } catch (error) {
            if (error.response) {
                const status = error.response.status;
    
                if (status === 401 || status === 403) {
                    navigate('/login'); 
                }
    
                return { success: false, message: error.response.data.message || 'Token inválido o expirado.' };
            } else 
                return { success: false, message: 'No se pudo conectar con el servidor.' };
        }
    };

    const value = {
        login,
        validateToken,
        checkIfAdmin,
        logout,
        requestPasswordReset,
        verifyResetToken,
        resetPassword,
        changePassword
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
}

export { AuthProvider, AuthContext };